//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "FileOperateData",
  props: {
    fileOperateData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      activeFileOperateTab: "内部",
      fileOperateTabs: ["内部", "外发"],
    };
  },
  methods: {
    handleTabClick(tab) {
      this.activeFileOperateTab = tab;
    },
  },
};
