var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"file-operate-data"},[_c('div',{staticClass:"file-operate-data-content"},[_c('div',{staticClass:"file-operate-title"},[_c('span',[_vm._v("文件操作统计")]),_c('div',{staticClass:"file-operate-tab"},_vm._l((_vm.fileOperateTabs),function(item,index){return _c('div',{key:index,class:_vm.activeFileOperateTab == item ? 'active' : '',on:{"click":function($event){return _vm.handleTabClick(item)}}},[_c('span',[_vm._v(_vm._s(item))])])}),0)]),_c('div',{staticClass:"file-operate-tab-panel"},[(
          _vm.activeFileOperateTab == '内部' &&
          _vm.fileOperateData &&
          _vm.fileOperateData.Inner
        )?_c('div',{staticClass:"file-operate-inner"},[_c('div',[_c('span',[_vm._v("创建")]),_c('span',[_vm._v(_vm._s(_vm.fileOperateData.Inner.Create))])]),_c('div',[_c('span',[_vm._v("拷贝")]),_c('span',[_vm._v(_vm._s(_vm.fileOperateData.Inner.Copy))])]),_c('div',[_c('span',[_vm._v("移动")]),_c('span',[_vm._v(_vm._s(_vm.fileOperateData.Inner.Move))])]),_c('div',[_c('span',[_vm._v("删除")]),_c('span',[_vm._v(_vm._s(_vm.fileOperateData.Inner.Delete))])]),_c('div',[_c('span',[_vm._v("重命名")]),_c('span',[_vm._v(_vm._s(_vm.fileOperateData.Inner.Rename))])])]):_vm._e(),(
          _vm.activeFileOperateTab == '外发' &&
          _vm.fileOperateData &&
          _vm.fileOperateData.Outer
        )?_c('div',{staticClass:"file-operate-outer"},[_c('div',[_c('span',[_vm._v("USB拷贝")]),_c('span',[_vm._v(_vm._s(_vm.fileOperateData.Outer.USB))])]),_c('div',[_c('span',[_vm._v("网络传输")]),_c('span',[_vm._v(_vm._s(_vm.fileOperateData.Outer.Network))])])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }